<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-user-list'"
        :title="'Create user'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.first_name"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('First name')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.last_name"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Last name')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.email"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('login.username')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.phone"
                dense
                outlined
                :label="$t('Phone')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.password"
                :rules="[validator.required, validator.passwordValidator]"
                type="password"
                dense
                outlined
                :label="$t('Password')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.password_confirmation"
                type="password"
                :rules="[validator.required, validator.passwordValidator]"
                dense
                outlined
                :label="$t('Password confirmation')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-select
                v-model="user.user_type"
                :rules="[validator.required]"
                :items="userTypeOptions"
                item-value="key"
                :item-text="item => $t(item.name)"
                dense
                outlined
                :label="$t('Type')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-select
                v-model="user.status"
                :rules="[validator.required]"
                dense
                outlined
                :items="statusOptions"
                :item-text="item => $t(item.name)"
                item-value="key"
                :label="$t('Status')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-select
                v-model="user.location"
                :rules="[validator.required]"
                :items="$store.getters.getStocks"
                dense
                outlined
                class="w-full mb-1"
                :label="$t('Location')"
                hide-details
                item-value="id"
                item-text="name"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-user-list'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import axiosIns from '@axios'
import {
  emailValidator, passwordValidator, required, confirmedValidator,
} from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const currentUser = JSON.parse(localStorage.getItem('userData'))
    const snackbarMessage = ''
    const errorMessages = []
    const userId = null
    const snackbar = false
    const loading = true
    const user = {}
    const statusFilter = ''
    const statusOptions = this.$store.state.baseStatuses
    const userTypeOptions = currentUser.role === 'super_admin'
      ? this.$store.state.userTypes
      : this.$store.state.userTypes.filter(type => type.key !== 'super_admin' && type.key !== 'admin')

    return {
      snackbarMessage,
      errorMessages,
      userTypeOptions,
      userId,
      snackbar,
      loading,
      statusFilter,
      user,
      statusOptions,

      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCellphoneKey,
        mdiEmailAlert,
      },

      validator: {
        emailValidator,
        passwordValidator,
        required,
        confirmedValidator,
      },
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        axiosIns({
          method: this.userId ? 'PUT' : 'POST',
          data: this.user,
          url: this.userId ? `/admin/users/${this.userId}` : '/admin/users',
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.userId = res.data.user.id
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
